import { Controller } from '@hotwired/stimulus';
import I18n from '../app/i18n';
import MpUploadAdapterPlugin from '../app/ckeditor/mp_uploader_adapter_plugin';

export default class extends Controller { 
  static values = {
    fileUploadUrl: String,
    toolbar: String,
  }

  connect() {
    const extraPlugins = this.fileUploadUrlValue ? [ MpUploadAdapterPlugin ] : [];

    const options = {
      toolbar: {
        items: this.toolbarItems(),
        shouldNotGroupWhenFull: true,
      },
      heading: {
        options: [
            { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
            { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
            { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
            { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
            { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
            { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
            { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
        ]
      },
      extraPlugins: extraPlugins,
      language: I18n.locale(),
      fontSize: {
        options: [ 8, 9, 10, 11, 12, 14, 16, 18, 24 ],
        supportAllValues: true
      },
      fileUploadUrl: this.fileUploadUrlValue,
      placeholder: I18n.t('ckeditor.placeholder'),
      fontFamily: {
        options: [
          'default',
          'Verdana, Arial, sans-serif',
          'AbrilFatFace, Verdana, Arial, sans-serif',
          'BioRhyme, Verdana, Arial, sans-serif',
          'CenturyGothic, Verdana, Arial, sans-serif',
          'Chivo, Verdana, Arial, sans-serif',
          'Corinthia, Verdana, Arial, sans-serif',
          'Dosis, Verdana, Arial, sans-serif',
          'Enriqueta, Verdana, Arial, sans-serif',
          'Gothic, Verdana, Arial, sans-serif',
          'Halant, Verdana, Arial, sans-serif',
          'Jaldi, Verdana, Arial, sans-serif',
          'MavenPro, Verdana, Arial, sans-serif',
          'Merriweather, Verdana, Arial, sans-serif',
          'Merriweather Sans, Verdana, Arial, sans-serif',
          'Montserrat, Verdana, Arial, sans-serif',
          'Open Sans, Verdana, Arial, sans-serif',
          'Oswald, Verdana, Arial, sans-serif',
          'PlayFair, Verdana, Arial, sans-serif',
          'Quicksand, Verdana, Arial, sans-serif',
          'Raleway, Verdana, Arial, sans-serif',
          'Roboto, Verdana, Arial, sans-serif',
          'Roboto Slab, Verdana, Arial, sans-serif',
          'Rockwell, Verdana, Arial, sans-serif',
          'Sansita, Verdana, Arial, sans-serif',
          'Spectral, Verdana, Arial, sans-serif',
        ],
        supportAllValues: true
      },
      removePlugins: [
        'ExportPdf',
        'ExportWord',
        'AIAssistant',
        'CKBox',
        'CKFinder',
        'EasyImage',
        'Base64UploadAdapter',
        'MultiLevelList',
        'RealTimeCollaborativeComments',
        'RealTimeCollaborativeTrackChanges',
        'RealTimeCollaborativeRevisionHistory',
        'PresenceList',
        'Comments',
        'TrackChanges',
        'TrackChangesData',
        'RevisionHistory',
        'Pagination',
        'WProofreader',
        // Careful, with the Mathtype plugin CKEditor will not load when loading this sample
        // from a local file system (file://) - load this site via HTTP server if you enable MathType.
        'MathType',
        // The following features are part of the Productivity Pack and require additional license.
        'SlashCommand',
        'Template',
        'DocumentOutline',
        'FormatPainter',
        'TableOfContents',
        'PasteFromOfficeEnhanced',
        'CaseChange',
      ],
    }

    CKEDITOR.ClassicEditor
        .create(this.element, options)
        .catch((error) => {
            console.error(error);
        });
  }

  toolbarItems() {
    if (this.toolbarValue === 'optin') {
      return [
        'heading', '|',
        'bold', 'italic', 'strikethrough', 'underline', 'removeFormat', '|',
        'bulletedList', 'numberedList', 'todoList', '|',
        'outdent', 'indent', '|',
        'undo', 'redo',
        '-',
        'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', '|',
        'alignment', '|',
        'link', '|',
        'horizontalLine', '|',
      ]
    } else {
      return [
        'heading', '|',
        'bold', 'italic', 'strikethrough', 'underline', 'code', 'subscript', 'superscript', 'removeFormat', '|',
        'bulletedList', 'numberedList', 'todoList', '|',
        'outdent', 'indent', '|',
        'undo', 'redo',
        '-',
        'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor', '|',
        'alignment', '|',
        'link', 'uploadImage', 'blockQuote', 'code', '|',
        'horizontalLine', 'pageBreak', '|',
        'sourceEditing'  
      ]
    }
  }

}  